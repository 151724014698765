<template>
  <div class="healthIQ">
    <!-- 卡片视图区域 -->
    <el-card>
      <el-row>
        <el-col class="tabbar">
          <div class="">
            <el-input
              size="medium"
              class="demo-input-label"
              placeholder="请输入内容"
              maxlength="10"
              v-model="params.keyWord"
              clearable
            >
            </el-input>
            <el-button
              type="primary"
              size="medium"
              class="demo-input-label"
              icon="el-icon-search"
              @click="searchClick"
              >搜索</el-button
            >
            <el-button
              type="primary"
              size="medium"
              class="demo-input-label"
              icon="el-icon-plus"
              @click="addInfo()"
              >添加</el-button
            >
          </div>
          <div style="display: flex">
            <el-upload
              action="/busiweb/healthIQ/Excel/healthIQExcelImport"
              accept=".xlsx, .xls"
              ref="upload"
              :on-success="onSuccess"
              limit="1"
              show-file-list="false"
            >
              <el-button
                class="demo-input-label"
                type="primary"
                size="medium"
                style=""
                icon="el-icon-upload2"
                >点击上传</el-button
              >
            </el-upload>
            <el-button
              type="primary"
              size="medium"
              class="demo-input-label"
              icon="el-icon-download"
              @click="healthExcelExportFile"
              >导出</el-button
            >
          </div>
        </el-col>
        <el-col class="tabbar" style="margin-top: 20px">
          <div class="">
            <el-cascader
              collapse-tags
              v-model="projectId"
              :options="dictList"
              :props="props"
              @change="handleChange"
              clearable
            ></el-cascader>
          </div>
        </el-col>
      </el-row>
    </el-card>
    <div class="main">
      <el-table
        :data="records"
        border
        style="width: 98%"
        :height="tableHeight"
        class="loading"
      >
        <!-- 展开列 -->
        <el-table-column type="expand">
          <template slot-scope="scope">
            <el-tag
              v-for="item1 in scope.row.questionDicts"
              class="tags"
              :key="item1.id"
              >{{ item1.name }}</el-tag
            >
          </template>
        </el-table-column>
        <el-table-column type="index" width="100"> </el-table-column>
        <el-table-column
          prop="question"
          label="题目"
          width="300"
          :show-overflow-tooltip="true"
        >
        </el-table-column>
        <el-table-column
          prop="answer"
          label="答案"
          :show-overflow-tooltip="true"
        >
        </el-table-column>
        <el-table-column
          prop="question"
          label="是否正确"
          width="180"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            <el-tag v-if="scope.row.answerFlag == '1'">正确</el-tag>
            <el-tag type="warning" v-else>错误</el-tag>
          </template>
        </el-table-column>

        <el-table-column label="操作" width="300">
          <template slot-scope="scope">
            <el-button
              type="primary"
              icon="el-icon-edit"
              size="mini"
              @click="addInfo(scope.row)"
              >编辑</el-button
            >
            <el-button
              type="danger"
              icon="el-icon-delete"
              size="mini"
              @click="removeById(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
        <template slot="opt"> > </template>
      </el-table>
      <!-- 分页区域 -->
      <pagination
        :pagingParams="params"
        :total="total"
        @pagingSizeChange="handleSizeChange"
        @pagingNumChange="handleCurrentChange"
      ></pagination>
    </div>
    <!-- 修改用户的对话框 -->
    <el-dialog
      title="添加题目"
      :visible.sync="setRoleDialogVisible"
      width="50%"
      @close="editDialogClosed"
    >
      <el-form :model="saveHealthIQ" ref="editFormRef" label-width="70px">
        <el-form-item label="题目">
          <el-input v-model="saveHealthIQ.answer"></el-input>
        </el-form-item>
        <el-form-item label="是否正确">
          <el-radio v-model="saveHealthIQ.answerFlag" :label="1">正确</el-radio>
          <el-radio v-model="saveHealthIQ.answerFlag" :label="2">错误</el-radio>
        </el-form-item>
        <el-form-item label="答案">
          <el-input v-model="saveHealthIQ.question"></el-input>
        </el-form-item>

        <el-form-item label="标签" prop="parentId">
          <el-tree
            ref="tagTree"
            :data="cascaderDictList"
            highlight-current
            show-checkbox
            node-key="id"
            default-expand-all
            :default-checked-keys="defaultChecked"
            :props="{
              children: 'children',
              label: 'name',
            }"
          >
          </el-tree>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="setRoleDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="saveInfo">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import pagination from "@/components/common/pagination/Index.vue";
import { Decrypt } from "@/utils/common/encryp";
export default {
  name: "AdminDemoIndex",
  components: {
    pagination,
  },
  data() {
    return {
      tableHeight: window.innerHeight - 350,
      props: {
        label: "name",
        value: "id",
        multiple: true,
        emitPath: true,
      },
      params: { pageNum: 1, pageSize: 10, keyWord: "" },
      records: [],
      total: 0,
      isIndeterminate: false,
      saveHealthIQ: {
        answer: "",
        answerFlag: 0,
        question: "",
        dictIds: [],
      },
      tree: [15014],
      addDialog: true,
      // 控制分配角色对话框的显示与隐藏
      setRoleDialogVisible: false,
      cascaderDictList: [],

      defaultChecked: [],
    };
  },

  mounted() {
    this.findList();
    this.getTagsList();
    window.onresize = () => {
      this.tableHeight = window.innerHeight - 350;
    };
  },
  methods: {
    handleChange(val) {
      this.params.tags = val.map((item) => item[item.length - 1]);
      this.findList();
    },
    async getTagsList() {
      const res = await this.$API.getDictList({ dictType: 1 });
      if (res.status !== 200) return "";
      this.dictList = res.data;
      this.cascaderDictList = this.dictList;
    },
    onSuccess(res) {
      this.importDataText = "导入数据";
      this.importDataIcon = "el-icon-upload2";
      if (res.status == 200) {
        this.$message.success(res.msg);
        this.$refs.upload.clearFiles();
        this.findList();
      } else {
        this.$message.error(res.msg);
        this.$refs.upload.clearFiles();
      }
    },
    async healthExcelExportFile() {
      const { keyWord } = this.params;
      const res = await this.$API.healthExcelExportFile({ keyWord });
      const link = document.createElement("a");
      let blob = new Blob([res], { type: "application/vnd.ms-excel" });
      link.style.display = "none";
      link.href = URL.createObjectURL(blob);
      link.download = "healthIQ.xlsx";
      document.body.appendChild(link);
      link.click();
    },

    setRoleDialogClosed() {},
    async saveInfo() {
      const tagList = this.$refs.tagTree.getCheckedNodes(true);

      this.saveHealthIQ.dictIds = tagList.map((item) => {
        return item.id;
      });
      const res = await this.$API.saveHealthIQ(this.saveHealthIQ);
      if (res.status !== 200) return this.$message.error("获取用户列表失败！");

      this.setRoleDialogVisible = false;
      this.$message.success(res.msg);
      this.params.pageNum = 1;
      this.params.keyWord = "";
      this.findList();
    },
    async removeById(item) {
      const { id, questionNo } = item;
      let params = {
        id,
        questionNo,
      };
      // 弹框询问用户是否删除数据
      const confirmResult = await this.$confirm(
        "此操作将永久删除该用户, 是否继续?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).catch((err) => err);
      if (confirmResult !== "confirm") {
        return this.$message.info("已取消删除");
      }

      const res = await this.$API.removeHealthIQ(params);

      if (res.status !== 200) {
        return this.$message.error("删除失败！");
      }
      this.$message.success("删除成功！");
      this.findList();
    },
    addInfo(item = {}) {
      this.defaultChecked = [];

      this.setRoleDialogVisible = true;

      if (item) {
        this.saveHealthIQ = item;

        let defaultChecked =
          item.questionDicts && JSON.parse(JSON.stringify(item.questionDicts));
        defaultChecked =
          defaultChecked.map((item) => {
            return item.dictId;
          }) || [];
        this.$nextTick(() => {
          this.$refs.tagTree.setCheckedKeys([]);
          this.defaultChecked = defaultChecked;
        });

        console.log(item);
      }
    },
    searchClick() {
      this.params.pageNum = 1;
      this.findList();
    },
    handleSizeChange(newSize) {
      console.log(newSize);
      this.params.pageSize = newSize;
      this.findList();
    },
    handleCurrentChange(newPage) {
      this.params.pageNum = newPage;
      this.findList();
    },
    handleCheckAllChange() {},
    async findList() {
      this.$showLoading("loading");
      const res = await this.$API.findHealthIQ(this.params);
      if (res.status == 200) {
        const { records, total } = JSON.parse(Decrypt(res.data));
        this.records = records;
        this.total = +total;
        console.log(this.total);
      }
      this.$hideLoading();
    },
  },
};
</script>

<style lang="scss" scoped>
.healthIQ {
  width: 100%;
  background: #fff;
}

// .el-cascader {
//   width: 100%;
// }
.tabbar {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

// .el-breadcrumb {
//   margin-bottom: 20px;
// }
.demo-input-label {
  display: inline-block;
  width: 130px;
  margin-right: 10px;
  margin-left: 0;
}
::v-deep.el-tree-node {
  .is-leaf + .el-checkbox .el-checkbox__inner {
    display: inline-block;
  }
  .el-checkbox .el-checkbox__inner {
    display: none;
  }
}

.el-tag {
  margin: 7px;
  display: inline-block !important;
}
</style>
